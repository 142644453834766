import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import jwt_decode from 'jwt-decode';

// ----------------------------------------------------------------------

function objFromArray(array, key = 'id') {
	return array.reduce((accumulator, current) => {
		accumulator[current[key]] = current;
		return accumulator;
	}, {});
}

const initialState = {
	isLoading: false,
	error: false,
	contacts: { byId: {}, allIds: [] },
	conversations: { byId: {}, allIds: [] },
	chathistory: { byId: {}, allIds: [] },
	activeConversationId: null,
	participants: [],
	recipients: []
};

const slice = createSlice({
	name: 'chat',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true;
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false;
			state.error = action.payload;
		},

		// GET CONTACT SSUCCESS
		getContactsSuccess(state, action) {
			const contacts = action.payload;

			state.contacts.byId = objFromArray(contacts);
			state.contacts.allIds = Object.keys(state.contacts.byId);
		},

		// GET CONVERSATIONS
		getConversationsSuccess(state, action) {
			const conversations = action.payload;

			state.conversations.byId = objFromArray(conversations);
			state.conversations.allIds = Object.keys(state.conversations.byId);
		},

		setchathistory(state, action) {
			const conversations = action.payload;

			state.chathistory.byId = objFromArray(conversations);
			state.chathistory.allIds = Object.keys(state.conversations.byId);
		},

		// GET CONVERSATION
		getConversationSuccess(state, action) {
			const conversation = action.payload;

			if (conversation) {
				state.conversations.byId[conversation.id] = conversation;
				state.activeConversationId = conversation.id;
				if (!state.conversations.allIds.includes(conversation.id)) {
					state.conversations.allIds.push(conversation.id);
				}
			} else {
				state.activeConversationId = null;
			}
		},

		// ON SEND MESSAGE
		onSendMessage(state, action) {
			const conversation = action.payload;
			const { conversationId, messageId, message, contentType, attachments, createdAt, senderId } = conversation;

			const newMessage = {
				id: messageId,
				body: message,
				contentType,
				attachments,
				createdAt,
				senderId
			};

			console.log(newMessage);
			console.log(state.conversations)
			state.conversations.byId[conversationId].messages.push(newMessage);
		},

		markConversationAsReadSuccess(state, action) {
			const { conversationId } = action.payload;
			const conversation = state.conversations.byId[conversationId];
			if (conversation) {
				conversation.unreadCount = 0;
			}
		},

		// GET PARTICIPANTS
		getParticipantsSuccess(state, action) {
			const participants = action.payload;
			state.participants = participants;
		},

		// RESET ACTIVE CONVERSATION
		resetActiveConversation(state) {
			state.activeConversationId = null;
		},

		addRecipients(state, action) {
			const recipients = action.payload;
			state.recipients = recipients;
		}
	}
});

// Reducer
export default slice.reducer;

// Actions
export const { addRecipients, onSendMessage, resetActiveConversation } = slice.actions;

// ----------------------------------------------------------------------
export function getContacts() {
	const email = jwt_decode(localStorage.getItem('token')).email;

	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		const aaa = await axios.get('/api/chat/contacts');

		// console.log('sample contact', aaa);

		const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/getcontacts`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ email: email })
		});

		const result = await response.json();
		const contacts = result.contacts.map((val) => {
			return {
				id: val.smsto,
				username: val.firstname ? `${val.firstname} ${val.lastname}` : val.smsto,
				name: val.firstname ? `${val.firstname} ${val.lastname}` : val.smsto,
				email: val.email,
				phone: val.smsto,
				state: 'online',
				position: "UX Designer",
				lastActivity: val.createdAt,
				avatar: '/static/mock-images/avatars/avatar_1.jpg',
				address: "19034 Verna Unions Apt. 164 - Honolulu, RI / 87535",
			}
		});

		// console.log('result contact', contacts);
		dispatch(slice.actions.getContactsSuccess(contacts));
	};
}

// ----------------------------------------------------------------------

export function getConversations() {
	const email = jwt_decode(localStorage.getItem('token')).email;

	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			// const response = await axios.get('/api/chat/conversations');

			const response0 = await fetch(`${process.env.REACT_APP_SERVER_URL}/getcontacts`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ email: email })
			});

			const result = await response0.json();

			console.log(result);

			const groupedData = result.contacts.reduce((acc, obj) => {
				const { smsto, smsfrom } = obj;
				if (smsto !== '+12232107199') {
					if (!acc[smsto]) {
						acc[smsto] = [];
					}
					acc[smsto] = obj;
				}

				if (smsfrom !== '+12232107199') {
					console.log(obj)
					if (!acc[smsfrom]) {
						acc[smsfrom] = [];
					}
					acc[smsfrom] = obj;
				}

				return acc;
			}, {});

			const conversation = Object.values(groupedData)
				.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
				.map((val) => {
					let key = val.smsto === '+12232107199' ? val.smsfrom : val.smsto
					return {
						id: key,
						// eslint-disable-next-line array-callback-return
						messages: result.contacts
							.filter((val1) => key === val1.smsto || key === val1.smsfrom)
							.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
							.map((val1) => ({
								attachments: [],
								body: val1.body,
								contentType: 'text',
								createdAt: val1.createdAt,
								id: val1.createdAt,
								senderId: val1.smsto,
								status: val1.status
							})),
						participants: [
							{
								id: val.smsfrom,
								address: "1147 Rohan Drive Suite 819 - Burlington, VT / 82021",
								avatar: '/static/mock-images/avatars/avatar_15.jpg',
								email: val.user ? val.user : '',
								lastActivity: "2023-10-04T23:27:34.916Z",
								name: val.firstname ? `${val.firstname} ${val.lastname}` : val.smsfrom,
								phone: val.smsfrom,
								position: "Full Stack Designer",
								status: "away",
								username: val.firstname ? `${val.firstname} ${val.lastname}` : val.smsfrom,
							},
							{
								id: val.smsto,
								address: "1147 Rohan Drive Suite 819 - Burlington, VT / 82021",
								avatar: "/static/mock-images/avatars/avatar_2.jpg",
								email: val.user ? val.user : '',
								lastActivity: "2023-10-04T23:27:34.916Z",
								name: val.firstname ? `${val.firstname} ${val.lastname}` : val.smsto,
								phone: val.smsto,
								position: "Full Stack Designer",
								status: "away",
								username: val.firstname ? `${val.firstname} ${val.lastname}` : val.smsto,
							}
						],
						group: val.groupSMS,
						type: 'ONE_TO_ONE',
						unreadCount: 0
					}
				});

			// console.log('sample conversation', response)
			// console.log(678, conversation)
			dispatch(slice.actions.getConversationsSuccess(conversation));
			dispatch(slice.actions.setchathistory(conversation));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

// ----------------------------------------------------------------------

export function getConversation(conversationKey, chathistory) {
	return async (dispatch) => {
		dispatch(slice.actions.getConversationSuccess(chathistory.byId[conversationKey]));
	};
}

// ----------------------------------------------------------------------

export function markConversationAsRead(conversationId) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			await axios.get('/api/chat/conversation/mark-as-seen', {
				params: { conversationId }
			});
			dispatch(slice.actions.markConversationAsReadSuccess({ conversationId }));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

// ----------------------------------------------------------------------

export function getParticipants(conversationKey, chathistory) {
	// console.log('conversationKey', conversationKey)
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			// const response = await axios.get('/api/chat/participants', {
			//   params: { conversationKey }
			// });
			// dispatch(slice.actions.getParticipantsSuccess(response.data.participants));
			dispatch(slice.actions.getParticipantsSuccess(chathistory.byId[conversationKey].participants));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
