// import { Icon } from '@iconify/react';
// import googleFill from '@iconify/icons-eva/google-fill';
// import twitterFill from '@iconify/icons-eva/twitter-fill';
// import facebookFill from '@iconify/icons-eva/facebook-fill';
// import linkedinFill from '@iconify/icons-eva/linkedin-fill';
// import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, IconButton, Stack, Box } from '@mui/material';
// routes
// import { PATH_PAGE } from '../../routes/paths';
// //
// import Logo from '../../components/Logo';

// ----------------------------------------------------------------------

// const SOCIALS = [
//   { name: 'FaceBook', icon: facebookFill },
//   { name: 'Google', icon: googleFill },
//   { name: 'Linkedin', icon: linkedinFill },
//   { name: 'Twitter', icon: twitterFill }
// ];

const LINKS = [
  {
    headline: 'Pages',
    children: [
      { name: 'Home', href: '/' },
      { name: 'Dashboard', href: '/dashboard' },
    ]
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Conditions', href: '/terms-condition' },
      { name: 'Privacy Policy', href: '/privacy-policy' }
    ]
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={8} md={3}>
            <Box>
              <Box component="img" src="/static/logo.png" sx={{ width: '70px', mx: 'auto' }} />
              <Box sx={{ textAlign: 'center' }}>AIAnalyzer.io</Box>
            </Box>
            {/* <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              The starting point for your next project with Minimal UI Kit, built on the newest version of Material-UI
              ©, ready to be customized to your style.
            </Typography>

            <Stack
              spacing={1.5}
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              {SOCIALS.map((social) => (
                <IconButton key={social.name} color="primary" sx={{ p: 1 }}>
                  <Icon icon={social.icon} width={16} height={16} />
                </IconButton>
              ))}
            </Stack> */}
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
              {LINKS.map((list) => {
                const { headline, children } = list;
                return (
                  <Stack key={headline} spacing={2}>
                    <Typography component="p" variant="overline" sx={{ fontSize: '0.9rem !important' }}>
                      {headline}
                    </Typography>
                    {children.map((link) => (
                      <Link
                        to={link.href}
                        key={link.name}
                        color="inherit"
                        variant="body2"
                        component={RouterLink}
                        sx={{ display: 'block', fontSize: '0.9rem !important' }}
                      >
                        {link.name}
                      </Link>
                    ))}
                  </Stack>
                );
              })}
              <Stack spacing={2}>
                <Typography component="p" variant="overline" sx={{ fontSize: '0.9rem !important' }}>
                  Contact
                </Typography>
                <a style={{ display: 'block', fontSize: '0.9rem !important', color: '#212B36', textDecoration: 'none' }} href='mailto:support@aianalyzer.io'>support@aianalyzer.io</a>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 15,
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          AIAnalyzer.io © 2024. All rights reserved
        </Typography>
      </Container>
    </RootStyle>
  );
}
