// import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import SvgIconStyle from '../../components/SvgIconStyle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCircleUser, faCreditCard, faBarChart } from '@fortawesome/free-regular-svg-icons';
import jwt_decode from 'jwt-decode';

const userinfo = jwt_decode(localStorage.getItem('token'));
const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking')
};

const sidebarConfig1 =
  [
    {
      subheader: '',
      items: [
        {
          title: 'Opportunities',
          path: '/dashboard/nih',
          icon: ICONS.blog
        },
        {
          title: 'Category',
          path: '/dashboard/category',
          icon: ICONS.booking
        },
        {
          title: 'Train',
          path: '/dashboard/train',
          icon: ICONS.analytics
        },
        {
          title: 'Embedding History',
          path: '/dashboard/history',
          icon: <FontAwesomeIcon icon={faClock} size='lg' />
        },
        {
          title: 'Generate',
          path: '/dashboard/generate',
          icon: ICONS.chat
        },
      ]
    },
  ];

const sidebarConfig2 =
  [
    {
      subheader: '',
      items: [
        {
          title: 'Dashboard',
          path: '/dashboard/index',
          icon: ICONS.analytics
        },
        {
          title: 'Analyze',
          path: '/dashboard/analyze',
          icon: <FontAwesomeIcon icon={faBarChart} size='lg' />
        },
        {
          title: 'History',
          path: '/dashboard/history',
          icon: <FontAwesomeIcon icon={faClock} size='lg' />
        },
        {
          title: 'Payment',
          path: '/dashboard/payment/history',
          icon: <FontAwesomeIcon icon={faCreditCard} size='lg' />
        },
        {
          title: 'Setting',
          path: '/dashboard/user/account',
          icon: <FontAwesomeIcon icon={faCircleUser} size='lg' />
        },
      ]
    },
    {
      subheader: 'Admin area',
      items: [
        {
          title: "Blog",
          icon: ICONS.blog,
          children: [
            { title: 'New post', path: '/dashboard/blog/new-post' },
            { title: 'List', path: '/dashboard/blog/list' },
          ]
        },
        {
          title: "Feedback",
          icon: ICONS.mail,
          path: '/dashboard/feedback',
        },
        {
          title: "Premium Link",
          icon: ICONS.kanban,
          path: '/dashboard/freepremium',
        },
        {
          title: "Subscription",
          icon: ICONS.ecommerce,
          path: '/dashboard/subscription',
        },
      ]
    },
  ];


export default userinfo.role === 'user' ? sidebarConfig1 : sidebarConfig2;
