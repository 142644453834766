import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';
import roundGrain from '@iconify/icons-ic/round-grain';
// routes

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22
};

const menuConfig = [
  {
    title: 'Home',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    path: '/'
  },
  {
    title: 'Dashboard',
    icon: <Icon icon={roundGrain} {...ICON_SIZE} />,
    path: '/dashboard/index'
  },
  // {
  //   title: 'Blog',
  //   icon: <Icon icon={roundGrain} {...ICON_SIZE} />,
  //   path: '/blog/posts'
  // },
  {
    title: 'Feedback',
    icon: <Icon icon={roundGrain} {...ICON_SIZE} />,
    path: '/feedback'
  },
  // {
  //   title: 'Faqs',
  //   icon: <Icon icon={roundGrain} {...ICON_SIZE} />,
  //   path: '/faqs'
  // },
  // {
  //   title: 'Dashboard',
  //   icon: <Icon icon={roundGrain} {...ICON_SIZE} />,
  //   path: '/dashboard/'
  // },
  // {
  //   title: 'Blog',
  //   icon: <Icon icon={roundGrain} {...ICON_SIZE} />,
  //   path: PATH_PAGE.about
  // },
  // {
  //   title: 'About us',
  //   icon: <Icon icon={roundGrain} {...ICON_SIZE} />,
  //   path: PATH_PAGE.about
  // },
  // {
  //   title: 'Contact us',
  //   icon: <Icon icon={roundGrain} {...ICON_SIZE} />,
  //   path: PATH_PAGE.contact
  // },
  // {
  //   title: 'Components',
  //   icon: <Icon icon={roundGrain} {...ICON_SIZE} />,
  //   path: PATH_PAGE.components
  // },
  // {
  //   title: 'Pages',
  //   path: '/pages',
  //   icon: <Icon icon={fileFill} {...ICON_SIZE} />,
  //   children: [
  //     {
  //       subheader: 'Other',
  //       items: [
  //         { title: 'About us', path: PATH_PAGE.about },
  //         { title: 'Contact us', path: PATH_PAGE.contact },
  //         { title: 'FAQs', path: PATH_PAGE.faqs },
  //         { title: 'Pricing', path: PATH_PAGE.pricing },
  //         { title: 'Payment', path: PATH_PAGE.payment },
  //         { title: 'Maintenance', path: PATH_PAGE.maintenance },
  //         { title: 'Coming Soon', path: PATH_PAGE.comingSoon }
  //       ]
  //     },
  //     {
  //       subheader: 'Authentication',
  //       items: [
  //         { title: 'Login', path: PATH_AUTH.loginUnprotected },
  //         { title: 'Register', path: PATH_AUTH.registerUnprotected },
  //         { title: 'Reset password', path: PATH_AUTH.resetPassword },
  //         { title: 'Verify code', path: PATH_AUTH.verify }
  //       ]
  //     },
  //     {
  //       subheader: 'Error',
  //       items: [
  //         { title: 'Page 404', path: PATH_PAGE.page404 },
  //         { title: 'Page 500', path: PATH_PAGE.page500 }
  //       ]
  //     },
  //     {
  //       subheader: 'Dashboard',
  //       items: [{ title: 'Dashboard', path: PATH_DASHBOARD.root }]
  //     }
  //   ]
  // },
];

export default menuConfig;
