import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
  color: PropTypes.string
};

export default function Logo({ sx, color = "#000", ...props }) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', ...sx, color: color }}>
      <img id='logo1' src='/static/logo.png' alt="AIAnalyzer.io Logo - Advanced AI-Powered Data Analysis" />
      {/* <Typography variant="h3">Opportunity</Typography> */}
    </Box>
  );
}
