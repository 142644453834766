import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import Login from '../pages/authentication/Login';


AuthGuard.propTypes = {
  children: PropTypes.node
};

export default function AuthGuard({ children }) {
  const { pathname } = useLocation();

  console.log('pathname', pathname)

  if (!localStorage.getItem('token') || !jwt_decode(localStorage.getItem('token')).role || jwt_decode(localStorage.getItem('token')).email === 'unknown@gmail.com') {
    if (pathname === '/feedback') {
      window.location.href = '/#/auth/login'
    }
    else {
      return <Login />;
    }
  }
  return <>{children}</>;
}
